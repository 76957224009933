<template>
  <div class="home_container">

    <div class="home_top">
      <img src="../assets/logo.jpeg" style="width: 54px;height: 54px;object-fit: contain">
      <p class="top_title-1">小参</p>
      <p class="top_title-2">简单大世界</p>
      <div class="home-top-right">
        <div class="title">关于</div>
      </div>
    </div>

    <div style="overflow: auto;height: 92vh">
      <div class="home-content-1">
        <div class="content">
          <p class="xiaocan" style="font-weight: bold">小参</p>
          <p class="xiaocan" style="font-size: 26px;margin-top: 25px">简单大世界</p>
        </div>
      </div>

      <div style="width: 100%;padding: 80px 0px;text-align: center">

        <p class="home-title">小参是一款短内容订阅工具</p>
        <p class="home-title-1">使用新的方式来帮助你系统地获取感兴趣的信息</p>

      </div>

      <div style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center">
        <el-image :src="require('../assets/card1.png')" lazy
                  style="width: 400px;height: auto;object-fit: cover"></el-image>
        <p class="home-title-3">选择你感兴趣的主题</p>
      </div>
      <div style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center">
        <p class="home-title-3" style="margin-left: 0px;margin-right: 200px">订阅后每天及时推送</p>
        <el-image :src="require('../assets/card2.png')" lazy
                  style="width: 400px;height: auto;object-fit: cover"></el-image>
      </div>
      <div style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center">
        <el-image :src="require('../assets/card3.png')" lazy
                  style="width: 400px;height: auto;object-fit: cover"></el-image>
        <p class="home-title-3">收藏、点赞和添加评论</p>
      </div>

      <div class="home-content-2">
        <div class="content">
          <div style="display: flex;flex-direction:column;align-items: flex-start;margin-right: 100px">
            <p class="home-title-4">立刻下载小参</p>
            <p class="home-title-4" style="margin-top: 20px;font-size: 20px;line-height: 36px">
              可手机扫右方二维码进入下载页面，也可至各大应用<br/>商店下载</p>
            <p class="down-load" style="margin-top: 40px">下载</p>
          </div>
          <img src="../assets/card4.png"
               style="width: 500px;height: auto;object-fit: cover">
        </div>
      </div>
      <div class="bottom">
        <p>Copyright 小参 2022 | 上海无远教育科技有限公司 | 电信增值业务许可证号：沪B2-20210450 </p>
        <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备17039062号-15</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">

::-webkit-scrollbar {
  display: none;
}

.home_container {
  margin: 0 auto;
  max-width: 1920px;
  height: 100vh;
}

.home_top {
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .top_title-1 {
    margin-left: 25px;
    font-weight: bold;
    color: #222222;
    font-size: 17px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
  }

  .top_title-2 {
    margin-left: 10px;
    color: #222222;
    font-size: 17px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;

  }

  .home-top-right {
    margin-left: 600px;
    display: flex;
    flex-direction: row-reverse;
  }

  .title {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
    color: #1258AF;
    padding: 1px;
    border: dashed transparent;
    border-radius: 1px;

    &:hover {
      border: dashed rgba(0, 0, 0, 0.5);
    }
  }
}

.home-content-1 {
  width: 100%;
  height: 328px;
  background-image: url("../assets/bg2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 842.062px;
  background-color: transparent;
  background-position: 0px -187.336px;
  background-attachment: fixed;

  .content {
    background-color: rgba(5, 26, 52, 0.35);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .xiaocan {
      color: white;
      font-size: 72px;
      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
    }
  }
}


.home-content-2 {
  width: 100%;
  height: 1052px;
  background-image: url("../assets/bg1.jpg");
  background-repeat: no-repeat;
  background-color: transparent;
  background-attachment: fixed;
  background-size: 100% 851.4px;
  background-position: -8.3px 64.5172px;

  .content {
    backdrop-filter: blur(0px);
    background-color: rgba(5, 26, 52, 0.35);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .home-title-4 {
      color: white;
      font-size: 36px;
      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
    }

    .down-load {
      font-weight: bold;
      border-radius: 4px;
      box-shadow: 0 2px 0 #0e468c;
      cursor: pointer;
      background-color: #1258AF;
      padding: 12px 18px;
      color: white;
      font-size: 16px;
      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
    }
  }
}

.home-title {
  color: #1258AF;
  font-size: 29px;
  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
}

.home-title-1 {
  margin-top: 20px;
  color: #222222;
  font-size: 16px;
  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
}

.home-title-3 {
  margin-left: 80px;
  color: #1258AF;
  font-size: 32px;
  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
}


.bottom {
  line-height: 48px;
  text-align: center;
  width: 100%;
  padding: 100px 0px;
  font-size: 16px;
  color: #50555c;
  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;

}

</style>
